<template>
  <div class="accountInfo">
    <div class="accountInfoTitle">
      <span>账户信息</span>
    </div>
    <div class="accountInfoContent">
      <el-form ref="form" :model="form" label-width="60px">
        <el-form-item prop="img">
          <el-upload
            class="avatar-uploader"
            :action="actionUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="postData"
          >
            <img v-if="form.img" :src="form.img" class="avatar" />
            <i v-else class="avatar-uploader-icon">上传头像</i>
          </el-upload>
        </el-form-item>
        <el-form-item label="账号">
          <span class="borderStyle">{{ sessionInfoGetter.tel }}</span>
        </el-form-item>
         <el-form-item label="姓名">
          <span class="borderStyle">{{ form.firm_name }}</span>
        </el-form-item>
        <el-form-item label="角色" v-if="form.role_name">
          <span class="borderStyle">{{  form.role_name }}</span>
        </el-form-item>
        <!-- <el-form-item label="QQ" prop="qq">
          <el-input v-model="form.qq"></el-input>
        </el-form-item> -->
        <!-- <div class="submitIdentification">
          <el-button type="danger" @click="submitModify()">提交</el-button>
        </div> -->
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        img: null,
        email: null,
        firm_name: null,
        solid_phone: null,
        qq: null,
        role_name:null
      },
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      http.postFront(protocolFwd.param_getUserInfo).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            this.form = value;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.img = res.value;
      this.submitModify()
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    submitModify() {
      protocolFwd.param_updateFirm.param.trueName = this.form.firm_name;
      protocolFwd.param_updateFirm.param.solidPhone = this.form.solid_phone;
      protocolFwd.param_updateFirm.param.img = this.form.img;
      http.postFront(protocolFwd.param_updateFirm).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.$EL_MESSAGE(message);
        } else {
          this.$EL_MESSAGE(message);
        }
        this.getUserInfo();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.accountInfo {
  line-height: 32px;
}
.accountInfo .accountInfoTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.accountInfo .accountInfoTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.accountInfoContent {
  width: 350px;
  margin-top: 20px;
  line-height: 40px;
}
.accountInfoContent .itemTitle {
  text-align: right;
}
.borderStyle{
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  height: 40px;
  line-height: 40px;
  text-indent: 15px;
  color:#666666;
}
/* 头像 开始 */
.avatar-uploader-icon {
  display: block;
  font-size: 18px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  font-style: normal;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
/* 头像 结束 */
</style>
